
export let baseUrl="https://bvokal-backend-f3d857076e97.herokuapp.com/"


export const API = {
    login: "login/loginUser",
    // {{{{{{  admin apis }}}}}}}
    createUser: "user/createUser",
    createTeam:"team/createTeam",
    getTeams:"team/getTeams"

}






