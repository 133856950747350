import { Navigate, Outlet } from "react-router"
import { Sidebar } from "./components/Sidebar/Sidebar";

export const CheckAuth = ({ role }) => {
    let localRole;
    let userData = localStorage.getItem("userData")
    if (userData) {

        localRole = JSON.parse(userData).role
    }
    return (
        <>
            {role === localRole ?
                <Sidebar>
                    <Outlet>
                    </Outlet>
                </Sidebar>

                : <Navigate to={"/"} />}
        </>
    )
}