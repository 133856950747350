import { createSlice } from "@reduxjs/toolkit";


let initialState = {
    type: "success",
    msg: "",
    initial:true
}

export const ToastSlice = createSlice({
    name: "snackbar",
    initialState,
    reducers: {
        show: (state, action) => {
            return action.payload
        }
    }
})

export let { show, close } = ToastSlice.actions
