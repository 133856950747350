export const validateEmail = (email) => {
    return email.match(/\S+@\S+\.\S+/) ? true : false;
};

export const validatePassword = (password) => {
    return password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/) ? true : false
}


export const formValidation = (formData, fields) => {
    let error = [];
    let fieldsObj = {}
    fields.forEach((res) => {
        if (!formData[res]?.trim() || formData?.length === 0) {
            error.push(res)
            fieldsObj[res] = true
        }
        else {
            fieldsObj[res] = false
        }
    })
    return ({ keys: fieldsObj, errorFields: error })
}

