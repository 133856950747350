import React, { useRef, useState } from 'react'
import "./authentication.css"
import { Logo } from '../../components/common/Logo'
import { LoginAndAuthTemplate } from '../../components/common/LoginAndAuthTemplate'
import { PasswordInput } from '../../components/Inputs/PasswordInput'
import { FaArrowRightLong } from 'react-icons/fa6'
import { Link, useNavigate } from 'react-router-dom'
import { CustomInput } from '../../components/Inputs/CustomInput'
import { toast } from '../../utils/common'
import { validateEmail, validatePassword } from '../../utils/validation'
import { login } from '../../Api/login'


export const Login = () => {
    let formData = useRef({
        email: '',
        password: ''
    })
    let navigate = useNavigate()
    let [errors, setErrors] = useState({ email: false, password: false })
    function inpChange(event) {
        let { name, value } = event.target
        formData.current[name] = value
    }
    async function loginSubmit(e) {
        e.preventDefault()
        let { email, password } = formData.current
        try {
            setErrors({ email: !validateEmail(email), password: !validatePassword(password) })
            if (validateEmail(email) && validatePassword(password)) {
                let rr = await login({ email, password })
                console.log(rr)
                localStorage.setItem("userData", JSON.stringify(rr.data.data))
                navigate("/admin/teamManagement")
                window.location.reload()
            }
        }
        catch (err) {
            toast.error(err?.response?.data?.errormessage)
        }
    }
    return (
        <LoginAndAuthTemplate
            leftText={"Within a few clicks, unleash the might of your voice and watch it resonate across the digital realm, for in this age of social media, the power to be heard lies at your fingertips."} >
            <div className='auth_main'>
                <Logo />
                <form onSubmit={loginSubmit}>

                    <div className='mt-5 p-1'>
                        <label className='mb-2'>Email</label>
                        <div>
                            <CustomInput id="login-input-email" onChange={inpChange} name='email' type='email' placeholder="Email" />
                            {/* <input  className='custom_input' /> */}
                        </div>
                        {errors.email ? <small className='text-danger'>Email is required</small> : null}
                    </div>
                    <div className='mt-3 p-1'>
                        <label className='mb-2'>Password</label>
                        <PasswordInput id="login-input-password" name="password" onChange={inpChange} placeholder="Password" />
                        {errors.password ? <small className='text-danger'>Password is required</small> : null}
                    </div>
                    <Link className='no_underline' to={"/forgotPassword"}>
                        <div className='text-white text-center my-3 '>Forgot Password?</div>
                    </Link>
                    <div>
                        <button id='login-button' className='login_btn' type='submit'>Log In <FaArrowRightLong /></button>
                    </div>
                </form>
                <div className='or_con my-3 px-5'>
                    <div className='w-100 line'></div>
                    <div className='mx-2 pb-1'>or</div>
                    <div className='w-100 line'></div>
                </div>
                <div>
                    <button id='login-sso-button' className='login_btn'>Log In using SSO <FaArrowRightLong /></button>
                </div>
            </div>
        </LoginAndAuthTemplate>
    )
}
