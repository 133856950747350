import React from 'react'
import "./inputs.css"

export const Input = ({className,error,...props}) => {
  return (
    <div className='input_con'>
    <input {...props} className={`${error?"invalid_input":""} ${className}`}/>
    </div>
  )
}
