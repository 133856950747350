import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Page404 } from '../pages/Page404/Page404'
import { Login } from '../pages/Authentication/Login'
import { ForgotPassword } from '../pages/Authentication/ForgotPassword'
import { ResetPassword } from '../pages/Authentication/ResetPassword'
import { CheckAuth } from '../config'
import { allRoutes } from './allRoutes'

export const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='' element={<Login />} />
                <Route path='/forgotPassword' element={<ForgotPassword />} />
                <Route path='/resetPassword' element={<ResetPassword />} />

                {/* admin routes  */}
                <Route path='admin' element={<CheckAuth role={"ADMIN"} />}>
                    {allRoutes["ADMIN"].map((res, key) =>  (<Route key={key} path={res.path} element={res.element} /> ))}

                </Route>

                <Route path='*' element={<Page404 />} />

            </Routes>


        </BrowserRouter>
    )
}




