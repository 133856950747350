import React, { useEffect, useRef, useState } from 'react'
import { CreateTeam } from '../../../components/PagesComponents/Admin/TeamManagement/CreateTeam'
import "./teamManagement.css"
import { IoEye } from "react-icons/io5";
import { PiNotePencilFill } from "react-icons/pi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TableContainer } from '../../../components/common/TableContainer';
import { Pagination } from '../../../components/common/Pagination';
import { getTeams } from '../../../Api/Admin/TeamManagement';
import { Dialog } from 'primereact/dialog';
import { useNavigate } from 'react-router';

export const TeamManagement = () => {
    const navigate = useNavigate();

    const [visibleEdit, setVisibleEdit] = useState(false);
    const [VisibleBlockOrDelete, setVisibleBlockOrDelete] = useState(false);
    let [allTeams, setAllTeams] = useState([])
    let [page, setPage] = useState(1)
    const getAllTeams = useRef(async () => {
        try {
            let res = await getTeams(1, 20)
            console.log(res.data.data)
            setAllTeams(res.data.data.response)
        }
        catch (err) {
            console.log(err)
        }
    })

    useEffect(() => {
        getAllTeams.current()
    }, [])

    function pageChange(pg) {
        console.log(pg)
        setPage(pg)
    }

    function itemPerPageChange(e) {
        console.log(e)
    }
const handleClick=()=>{
navigate("/admin/teamManagement/teamMember")
}
    return (
        <>
            <div className={"team_management_con"}>
                <div className='flex end mb-3'>
                    <CreateTeam />
                </div>
                <div>
                    <TableContainer className={"table_container"}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Team Name</th>
                                    <th>Team Leader</th>
                                    <th>Members</th>
                                    <th className='text-center'>
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody >
                                {allTeams.map((res, index) => (
                                    
                                    <tr key={index}>
                                     {/* onClick(()={    navigate("") }) */}
                                        <td onClick={handleClick} style={{ cursor: 'pointer' }}>{res.team_name || ""}  </td>
                                        <td>Data 2</td>
                                        <td>{res.members_id.length || 0}</td>
                                        <td>
                                            <div className='text-center'>
                                                {/* <i className="icon1"><IoEye /></i> */}
                                                <i className="icon2"><PiNotePencilFill onClick={() => setVisibleEdit(true)} /></i>
                                                <i className="icon3"><RiDeleteBin6Line onClick={() => setVisibleBlockOrDelete(true)}  /></i>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Dialog
                            // header="Add User"
                            visible={visibleEdit}
                            onHide={() => setVisibleEdit(false)}
                            style={{ width: "50vw" }}
                            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                        >
                            <div className="editUserParent">
                                <div className="editUserCard">
                                    <form action="" className="editUserUserDetails">


                                        <div className="editUserHeaderDataForm">
                                        <div className='heading1 '>Edit Team</div>
                                            <div className="userName">
                                                <label htmlFor="userName">
                                                    <p className="Label">Team Name
</p>
                                                    <p className="medatoryLabel">*</p>
                                                </label>
                                                <input type="text" placeholder="Enter User Name" />
                                            </div>
                                            <div className="userEmail ">
                                                <label htmlFor="userEmail">
                                                    <p className="Label">Team Leader</p>
                                                    <p className="medatoryLabel">*</p>
                                                </label>
                                                <select className="form-select" aria-label="Default select example">
                                <option selected>Select Team Leader</option>
                               
                                <option value={1}><input type="checkbox"/> </option>
                                
                            </select>
                                            </div>
                                        </div>
                                        <div className="editUserBottom">
                                            <button
                                                type="button"
                                                className="cancel p-dialog-close"
                                                onClick={() => setVisibleEdit(false)}
                                            >
                                                Cancel
                                            </button>
                                            <button className="saveChanges">Save Changes</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Dialog>

                        <Dialog
        // position="top"
        // header="Add User"
        visible={VisibleBlockOrDelete}
        onHide={() => setVisibleBlockOrDelete(false)}
        style={{ width: "auto" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="deleteParent">
          <div className="deletePopup">
            <div className="popupText">
              <h1 className='heading1'> Delete Team</h1>
              <p>Are you sure you want to delete this team ?</p>
            </div>
            <div className="popupButtons">
              <button className="deleteButton">
              Delete
                  {/* <RiDeleteBin6Line />
                 : (
                  <MdBlockFlipped />
                ) */}
              </button>
              <button
                className="cancelButton"
                type="button"
                onClick={() => setVisibleBlockOrDelete(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Dialog>
                    </TableContainer>
                    <Pagination page={page - 1} onItemPerPageChange={itemPerPageChange} onChange={pageChange} rowPerPage={20} />
                </div>
            </div>

        </>
    )
}
