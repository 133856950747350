import React, { useState } from 'react'
import { LoginAndAuthTemplate } from '../../components/common/LoginAndAuthTemplate'
import { Logo } from '../../components/common/Logo'
import { PasswordInput } from '../../components/Inputs/PasswordInput'
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { formValidation } from '../../utils/validation';
export const ResetPassword = () => {

    let [passwordData, setPasswordData] = useState({
        password: "",
        confirmPassword: ""
    })

    let [required, setRequired] = useState({
        password: true,
        confirmPassword: true
    })
    function inpChange(event) {
        let { name, value } = event.target
        setPasswordData({ ...passwordData, [name]: value })
    }

    async function resetPassword() {
        try {
            let requiredFormData = formValidation(passwordData, ["password", "confirmPassword"])
            setRequired({ ...requiredFormData.keys })
        }
        catch (err) {
            console.log(err)
        }
    }

    return (
        <LoginAndAuthTemplate
            leftText={"Within a few clicks, unleash the might of your voice and watch it resonate across the digital realm, for in this age of social media, the power to be heard lies at your fingertips."}
        >
            <div className='auth_main'>
                <Logo />

                <form onSubmit={resetPassword}>

                    <div className='mt-5 p-1'>
                        <label className='mb-2'>New Password</label>

                        <div>
                            <PasswordInput
                                onChange={inpChange}
                                name={"password"}
                                value={passwordData.password}
                                placeholder="New Password"
                                error={required.password}
                            />
                        </div>
                        {/* <small className='text-danger'>Email is required</small> */}
                    </div>
                    <div className='mt-3 p-1'>
                        <label className='mb-2'>Confirm New Password</label>
                        <div>
                            <PasswordInput
                                onChange={inpChange}
                                name={"confirmPassword"}
                                value={passwordData.confirmPassword}
                                placeholder={"Confirm New Password"}
                                error={required.confirmPassword}
                            />
                        </div>
                        {/* <small className='text-danger'>Email is required</small> */}
                    </div>
                    <div className='mt-5 mb-3'>
                        <button className='login_btn'>Change Password <FaArrowRightLong /></button>
                    </div>
                </form>
                <Link className='no_underline' to={"/"}>
                    <div className='text-white text-center pointer'>Back to Login Page</div>
                </Link>
            </div>
        </LoginAndAuthTemplate>
    )
}
