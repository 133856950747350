import { TeamManagement } from "../pages/Admin/TeamManagement/TeamManagement";
import { SiAddthis } from "react-icons/si";
import { IoGridOutline } from "react-icons/io5";
import { CiCalendar } from "react-icons/ci";
import { BsFillPersonFill } from "react-icons/bs";
import UserManagement from "../pages/Admin/UserManagement/UserManagement";
import Overview from "../pages/Admin/Overview/Overview";
import { PostManagement } from "../pages/Admin/PostManagement/PostManagement";
import { ViewTeam } from "../pages/Admin/PostManagement/ViewTeam";
import TeamMember from "../components/PagesComponents/Admin/TeamManagement/TeamMember";
import { AddMember } from "../pages/Admin/TeamManagement/AddMember";

export const allRoutes = {
  ADMIN: [
    {
      path: "overview",
      name: "Overview",
      element: <Overview />,
      icon: <IoGridOutline />,
    },
    {
      path: "userManagement",
      name: "User Management",
      element: <UserManagement />,
      icon: <BsFillPersonFill />,
    },
    {
      path: "teamManagement",
      name: "Team Management",
      element: <TeamManagement />,
      icon: <SiAddthis />,
    },
    {
      path: "teamManagement/teamMember",
      element: <TeamMember />,
    },
    {
      path: "teamManagement/AddMember",
      element: <AddMember />,
    },
    {
      path: "postManagement",
      name: "Post Management",
      element: <PostManagement />,
      icon: <CiCalendar />,
    },

    {
      path: "postManagement/viewTeam",
      element: <ViewTeam />,
    },
  ],
};
