import React from 'react';
import { NoDataPage } from '../../../components/common/NoDataPage';

function Overview() {
  return (
    <div>
      <NoDataPage num={0}/>
    </div>
  );
}

export default Overview;
