import axios from "axios"
import { API, baseUrl } from "../api"

let token;
let userData = localStorage.getItem("userData")
if (userData) {
    token = JSON.parse(userData).token
}
let config = {
    headers: {
        Authorization: `Bearer ${token}`,
    }
  }
export const createTeam = async (data) => {

    return await axios.post(baseUrl+API.createTeam, data,config)
}

export const getTeams = async (page, perPageData) => {

    return await axios.get(baseUrl+`${API.getTeams}?page=${page}&perPageData=${perPageData}`,config)
}


















