import React, { useState } from 'react'
import { LoginAndAuthTemplate } from '../../components/common/LoginAndAuthTemplate'
import { Logo } from '../../components/common/Logo'
import { FaArrowRightLong } from 'react-icons/fa6'
import { Link } from 'react-router-dom'

export const ForgotPassword = () => {


    let [isEmailSent, setIsMailSent] = useState(false)

    function inpChange() {

    }

    return (
        <LoginAndAuthTemplate
            leftText={"Within a few clicks, unleash the might of your voice and watch it resonate across the digital realm, for in this age of social media, the power to be heard lies at your fingertips."}
        >
            <div className='auth_main'>
                <Logo />
                {!isEmailSent ? <>
                    <div className='mt-5 p-1'>
                        <label className='mb-2'>Email</label>
                        <div>
                            <input onChange={inpChange} name='email' placeholder='Email' type='email' className='custom_input' />
                        </div>
                        {/* <small className='text-danger'>Email is required</small> */}
                    </div>
                    <div className='my-4'>
                        <button className='login_btn' onClick={()=>setIsMailSent(true)}>Continue <FaArrowRightLong /></button>
                    </div>
                    <Link className='no_underline' to={"/"}>
                        <div className='text-white text-center pointer'>Back to Login Page</div>
                    </Link>
                </> :
                    <div className='verification_text'>
                        Verification mail, containing the change password link sent to the provided E-mail address.
                    </div>
                }
            </div>
        </LoginAndAuthTemplate>
    )
}
