import React, { useEffect, useRef, useState } from "react";
import { CreateTeam } from "../../../components/PagesComponents/Admin/TeamManagement/CreateTeam";
import "./postManagement.css";
import { IoEye } from "react-icons/io5";
import { PiNotePencilFill } from "react-icons/pi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TableContainer } from "../../../components/common/TableContainer";
import { Pagination } from "../../../components/common/Pagination";
import { getTeams } from "../../../Api/Admin/TeamManagement";
import profileUserManagement from "../../../assests/images/profileUserManagement.png";

export const ViewTeam = () => {
  let [allTeams, setAllTeams] = useState([]);
  let [page, setPage] = useState(1);
  const getAllTeams = useRef(async () => {
    try {
      let res = await getTeams(1, 20);
      console.log(res.data.data);
      setAllTeams(res.data.data.response);
    } catch (err) {
      console.log(err);
    }
  });

  const userName = [
    {
      id: 1,
      name: "Kiran Bera (Guntur)",
    },
    {
      id: 2,
      name: "Anima Swamy (Krishna)",
    },
    {
      id: 3,
      name: "Malini Bandi (Anantapur)",
    },
    {
      id: 4,
      name: "Ranjit Dutt (Kurnool)",
    },
    {
      id: 5,
      name: "Niraj Chaudhari (Chittoor)",
    },
    {
      id: 6,
      name: "Lochan Dass (Srikakulam)",
    },
    {
      id: 7,
      name: "Jagan Sood (Prakasam)",
    },
    {
      id: 8,
      name: "Abhilash Kulkarni (Eluru)",
    },
  ];

  useEffect(() => {
    getAllTeams.current();
  }, []);

  function pageChange(pg) {
    console.log(pg);
    setPage(pg);
  }

  function itemPerPageChange(e) {
    console.log(e);
  }

  return (
    <>
      <div className={"team_management_con"}>
        <div className="flex end mb-3"></div>
        <div>
          <TableContainer className={"table_container"}>
            <h1 className="main_heading"> Team Leaders</h1>
            <hr />
            <table>
              <tbody>
                {userName.map((res, index) => (
                  <tr key={index}>
                    <td className="d-flex gap-3 align-items-center px-3">
                      <img src={profileUserManagement} alt="" />
                      <div>{res?.name}</div>
                    </td>

                    <td className="custom_font_size px-3">608 total posts</td>
                    <td className="px-3">
                      <div className="viewPost_btn">View Post</div>
                    </td>

                    <td className="text-center custom_font_size px-3">
                      8 team Members
                    </td>

                    <td className="px-3">
                      <div className="viewPost_btn">View Post</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer>
          <Pagination
            page={page - 1}
            onItemPerPageChange={itemPerPageChange}
            onChange={pageChange}
            rowPerPage={20}
          />
        </div>
      </div>
    </>
  );
};
