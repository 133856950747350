import React, { useState } from 'react'
import "./sidebar.css"
import { Logo } from '../common/Logo'
import { allRoutes } from '../../routes/allRoutes'
import { NavLink } from 'react-router-dom'
import { GoBell } from "react-icons/go";
import { GoQuestion } from "react-icons/go";
import { FaRegUserCircle } from "react-icons/fa";
import { Dropdown } from 'primereact/dropdown'
import { useTranslation } from "react-i18next";

export const Sidebar = ({ children }) => {
    let currentRole = "ADMIN";
    let { t } = useTranslation()
    const [selectedCity, setSelectedCity] = useState(null);
    const cities = [
        { name: 'English', code: 'NY' },
        { name: 'Romen', code: 'RM' },
        
    ];

    return (
        <div className='sidebar_con'>
            <div className='left_con'>
                <div className='logo_main_con'>
                    <Logo />
                </div>
                {allRoutes[currentRole].map((res, key) => {
                    return (
                        <div key={key} className='navlink_con'>
                            <NavLink style={{ textDecoration: 'none' }} to={`/${currentRole.toLocaleLowerCase()}/${res.path}`}>
                                <div className="navlink">
                                    <div className='fs-4'>{res.icon}</div>
                                    <div>{t(res.name)}</div>
                                </div>
                            </NavLink>
                        </div>
                    )
                })}
            </div>
            <div className='right_con'>
                <div className='top_bar_con'>
                    <div className='nav_title'>
                        Team Management
                    </div>
                    <div className='flex align_center'>
                        <div>
                            <Dropdown
                                value={selectedCity}
                                onChange={(e) => setSelectedCity(e.value)}
                                options={cities}
                                 optionLabel="name"
                                className='language_select' placeholder='Language' />
                        </div>
                        <div className='icon_con'>
                            <GoBell />
                            <GoQuestion />
                            <FaRegUserCircle />
                        </div>
                    </div>
                </div>
                <div className='children_con'>
                    <div className='child_body'>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}
