import React, { useState } from 'react'
import { PrimaryButton } from '../../../Buttons/PrimaryButton'
import { Dialog } from 'primereact/dialog';
import "./teamManagement.css"
import { Input } from '../../../Inputs/Input';
import { useTranslation } from "react-i18next";
import { formValidation, validateEmail } from '../../../../utils/validation';
import { toast } from '../../../../utils/common';
import { createTeam } from '../../../../Api/Admin/TeamManagement';

export const CreateTeam = () => {
    let [dialog, setDialog] = useState(false)
    let { t } = useTranslation()

    let [errorFields, setErrorFields] = useState({
        team_name: false,
        name: false,
        email: false
    })

    let [teamData, setTeamData] = useState({
        team_name: "",
        name: "",
        email: "",
        role: "LEADER",
        position: "LEADER"
    })

    function inpChange(event) {
        let { name, value } = event.target
        setTeamData({ ...teamData, [name]: value })
    }

    async function createTeamSubmit(e) {
        e.preventDefault()
        try {
            let formValidationResult = formValidation(teamData, ["team_name", "name"])
            let isValidEmail = validateEmail(teamData.email)
            setErrorFields({ ...errorFields, ...formValidationResult.keys, email: !isValidEmail })

            if (formValidationResult.errorFields.length === 0 && isValidEmail) {
                await createTeam(teamData)
                resetForm()
                toast.success("Team Created Successfully")
            }


        }
        catch (err) {
            toast.error(err?.response?.data?.errormessage)
        }
    }
    function resetForm() {
        setDialog(false)
        setTeamData({
            team_name: "",
            name: "",
            email: "",
            role: "LEADER",
            position: "LEADER"
        })
        setErrorFields({
            team_name: false,
            name: false,
            email: false
        })
    }


    return (
        <div>
            <PrimaryButton id="create-team-btn" onClick={() => setDialog(true)} addIcon label={"Create Team"} />
            <Dialog style={{ padding: 0 }} className='createTeam_con' closable={false} draggable={false} visible={dialog} onHide={() => setDialog(false)}>
                <div className='mx-5'>
                    <div className='heading mt-3'>{t("Create New Team")}</div>
                    <form className='mt-4' onSubmit={createTeamSubmit}>
                        <div>
                            <label className='Label'>{t("Team Name")}<sup>*</sup></label><br />
                            <Input
                                id="team-name-inp"
                                onChange={inpChange}
                                error={errorFields.team_name}
                                name="team_name"
                                placeholder={t("Enter Team Name")}
                            />
                        </div>
                        <div className='mt-3'>
                            <label className='Label'>{t("Team Leader")}<sup>*</sup></label><br />
                            <select className="form-select" aria-label="Default select example">
                                <option selected>Select Team Leader</option>
                               
                                <option value={1}>fdg<input type="checkbox"/> One</option>
                                <option value={2}>Two</option>
                                <option value={3}>Three</option>
                            </select>


                            {/* <Input
                                id="team-name-inp"
                                onChange={inpChange}
                                error={errorFields.team_name}
                                name="team_name"
                                placeholder={t("Enter Team Name")}
                            /> */}
                        </div>
                        {/* <div className='or_con my-3 '>
                            <div className='w-100 line'></div>
                            <div className='mx-2 pb-1 team_leader'>{t("Team Leader")}</div>
                            <div className='w-100 line'></div>
                        </div> */}
                        {/* <div>
                            <label>{t("Name")}<sup>*</sup></label><br />
                            <Input
                                id="leader-name-inp"
                                onChange={inpChange}
                                error={errorFields.name}
                                name="name"
                                placeholder={t("Enter Team Leader's Name")}
                            />
                        </div> */}
                        {/* <div className='mt-3'>
                            <label>{t("Email Address")}<sup>*</sup></label><br />
                            <Input
                                id="leader-email-btn"
                                onChange={inpChange}
                                name="email"
                                error={errorFields.email}
                                type='email'
                                placeholder={t("Enter Email Address")}
                            />
                            {errorFields.email ? <small className='error'>Please enter a valid email</small> : null}
                        </div> */}
                        <div className='flex end mt-4 mb-3'>
                            <button id="create-team-cancel-btn" type='button' onClick={resetForm} className='btn_secondary'>{t("Cancel")}</button>
                            <PrimaryButton id="create-team-submit-btn" type="submit" className="ms-3" label={t("Create")} />
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    )
}
