import React, { useEffect, useState } from 'react'
import { Paginator } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';

export const Pagination = ({ page, rowPerPage, onChange,onItemPerPageChange }) => {

   const [first, setFirst] = useState(page);
    const [rows, setRows] = useState(rowPerPage);

    const onPageChange = (event) => {
        onChange(event.page+1);
        setFirst(event.first)
        setRows(event.rows);
    };

    const template2 = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 100, value: 100 }
            ];

            return (
                <React.Fragment>
                    <span style={{ color: 'var(--text-color)', userSelect: 'none' }}>
                        Items per page:{' '}
                    </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    function rowChange(){
        
    }

    return (
        <div className='pagination_con'>
            <Paginator template={template2} first={first} rows={rows} totalRecords={120} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} className="justify-content-end" />
        </div>
    )
}
