import { Toast } from 'primereact/toast';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import "./common.css";
import { RxCrossCircled } from "react-icons/rx";

export const CustomToast = () => {
  let toastData = useSelector(e => e.toast);
  let toastRef = useRef(null);
  let closeTimeout = useRef()
  let closeTime = 4000

  useEffect(() => {
    if (!toastData.initial) {
      toastRef.current.show(toastData);
      closeTimeout.current = setTimeout(() => {
        close()
        clearTimeout(closeTimeout.current)
      }, closeTime);
    }
  }, [toastData,closeTime]);

  const close = () => toastRef.current.clear();

  const toastContent = (
    <div className='customToast_main_con'>
    <section className={`customToast_con ${toastData.severity}`}>
    
      <div className='message_con'>
        <div className={` ${toastData.severity}_item`}></div>
        <div className='message'>{toastData.detail}</div>
      </div>
      <div className='pe-2 fs-4 pointer' onClick={close}>
        <RxCrossCircled />
      </div>
    </section>
    </div>
  );




  return (
    <Toast ref={toastRef} content={toastContent} />
  );
};
