import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";
import { PiNotePencilFill } from "react-icons/pi";
import { RiDeleteBin6Line } from "react-icons/ri";
import "./DeleteOrBlockUser.css";
import { MdBlockFlipped } from "react-icons/md";

function DeleteUser({ action }) {
  const [VisibleBlockOrDelete, setVisibleBlockOrDelete] = useState(false);
  const handLechange =(e)=>{
    e.preventDefault()
    setVisibleBlockOrDelete(true)
    
  }
  return (
    <div className="deleteUser">
      {action === "delete" ? (
        <RiDeleteBin6Line onClick={() => setVisibleBlockOrDelete(true)} />
      ) : (
        <button className="block" onClick={handLechange}>
          <p>Block</p>
          <MdBlockFlipped className="blockButtonIcon" />
        </button>
      )}
      <Dialog
        // position="top"
        // header="Add User"
        visible={VisibleBlockOrDelete}
        onHide={() => setVisibleBlockOrDelete(false)}
        style={{ width: "auto" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="deleteParent">
          <div className="deletePopup">
            <div className="popupText">
              <h1>{action} User</h1>
              <p>Are you sure you want to {action} this user ?</p>
            </div>
            <div className="popupButtons">
              <button className="deleteButton">
                {action}
                {action === "delete" ? (
                  <RiDeleteBin6Line />
                ) : (
                  <MdBlockFlipped />
                )}
              </button>
              <button
                className="cancelButton"
                type="button"
                onClick={() => setVisibleBlockOrDelete(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default DeleteUser;
