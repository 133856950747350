import React from "react"
import "./button.css"

export const PrimaryButton = ({ label, addIcon, className, ...props }) => {
    return (
        <>
            <button className={`btn_primary ${className}`} {...props}>
                {addIcon ?
                    <div className="flex me-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <g clipPath="url(#clip0_1076_38619)">
                                <path d="M8.59863 15C8.59863 15.2984 8.71716 15.5845 8.92814 15.7955C9.13912 16.0065 9.42526 16.125 9.72363 16.125C10.022 16.125 10.3081 16.0065 10.5191 15.7955C10.7301 15.5845 10.8486 15.2984 10.8486 15V10.125H15.7236C16.022 10.125 16.3081 10.0065 16.5191 9.7955C16.7301 9.58452 16.8486 9.29837 16.8486 9C16.8486 8.70163 16.7301 8.41548 16.5191 8.2045C16.3081 7.99353 16.022 7.875 15.7236 7.875H10.8486V3C10.8486 2.70163 10.7301 2.41548 10.5191 2.2045C10.3081 1.99353 10.022 1.875 9.72363 1.875C9.42526 1.875 9.13912 1.99353 8.92814 2.2045C8.71716 2.41548 8.59863 2.70163 8.59863 3V7.875H3.72363C3.42526 7.875 3.13912 7.99353 2.92814 8.2045C2.71716 8.41548 2.59863 8.70163 2.59863 9C2.59863 9.29837 2.71716 9.58452 2.92814 9.7955C3.13912 10.0065 3.42526 10.125 3.72363 10.125H8.59863V15Z" fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1076_38619">
                                    <rect width="18" height="18" fill="white" transform="translate(0.723633)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div> : null}
                <div>{label}</div>
            </button>
        </>
    )
}