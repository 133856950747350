import React from 'react'
import "./common.css"

export const LoginAndAuthTemplate = ({children,leftText}) => {
    return (
        <div className='login_auth_template_main'>
            <section className='left'>
                <div className='left_text'>{leftText}</div>
            </section>
            <section className='right'>
                <div className='right_content'>
                   {children}
                </div>
            </section>
        </div>
    )
}
