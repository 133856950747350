import React from 'react'
import { PiNotePencilFill } from 'react-icons/pi'
import { TableContainer } from '../../../components/common/TableContainer'
import { RiDeleteBin6Line } from 'react-icons/ri'

export const AddMember = () => {
  return (
    <>
    <div>
    <TableContainer className={"table_container"}>
  <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    
                                    
                                    
                                    <th>Email</th>
                                    <th className='text-center'>
                                        <button className='AddButton' onClick={{AddMember}}>Save Changes</button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody >
                                {/* {allTeams.map((res, index) => ( */}
                                    
                                    <tr >
                                     {/* onClick(()={    navigate("") }) */}
                                        
                                        <td> 
                               
  <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
  <label className="form-check-label" htmlFor="flexCheckDefault">
  </label> &nbsp


                                             Roodra Lata</td>
                                        <td>test@gmail.com</td>
                                        <td>
                                            <div className='text-center'>
                                                
                                                  <i className="icon2 pointer"><PiNotePencilFill  /></i>
                                                <i className="icon3 pointer"><RiDeleteBin6Line  /></i>  
                                             </div> 
                                        </td>
                                    </tr>
                                    <tr >
                                     {/* onClick(()={    navigate("") }) */}
                                        
                                        <td>
                                        <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
  <label className="form-check-label" htmlFor="flexCheckDefault">
  </label> &nbsp
  <img src='' className='Profillogo'/>
                                            Roodra Lata</td>
                                        <td>test@gmail.com</td>
                                        
                                        
                                        <td>
                                            <div className='text-center'>
                                                
                                                  <i className="icon2 pointer"><PiNotePencilFill  /></i>
                                                <i className="icon3 pointer"><RiDeleteBin6Line  /></i>  
                                             </div> 
                                        </td>
                                    </tr>
                                    <tr >
                                     {/* onClick(()={    navigate("") }) */}
                                        
                                        <td>
                                        <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
  <label className="form-check-label" htmlFor="flexCheckDefault">
  </label> &nbsp
                                            Roodra Lata
                                            </td>
                                        <td>test@gmail.com</td>
                                        
                                        
                                        <td>
                                            <div className='text-center'>
                                                
                                                  <i className="icon2 pointer"><PiNotePencilFill  /></i>
                                                <i className="icon3 pointer"><RiDeleteBin6Line  /></i>  
                                             </div> 
                                        </td>
                                    </tr>
                                {/* ))} */}
                            </tbody>
                        </table>
                        </TableContainer>
                     
    </div>
    </>
  )
}
