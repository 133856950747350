import React, { useState } from 'react'
import { TableContainer } from '../../../common/TableContainer'
import { PiNotePencilFill } from 'react-icons/pi'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { Dialog } from 'primereact/dialog'
import { IoEye } from 'react-icons/io5'
import "./teamManagement.css"
import { useNavigate } from 'react-router'

const TeamMember = () => {
  const navigate = useNavigate()
  let [allTeams, setAllTeams] = useState([])
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [VisibleBlockOrDelete, setVisibleBlockOrDelete] = useState(false);
  const [VisibleBlock, setVisibleBlock] = useState(false);
  const AddMember =()=>{
navigate=("/admin/teamManagement/AddMember")
  }
  return (
  <>
  <div>
  <TableContainer className={"table_container"}>
  <table>
                            <thead>
                                <tr>
                                    <th>Guntur team Members <PiNotePencilFill className='icon2'/></th>
                                    
                                    
                                    
                                    <th></th>
                                    <th className='text-center'>
                                        <button className='AddButton' onClick={{AddMember}}>Add/Remove</button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody >
                                {/* {allTeams.map((res, index) => ( */}
                                    
                                    <tr >
                                     {/* onClick(()={    navigate("") }) */}
                                        
                                        <td>Roodra Lata</td>
                                        <td>test@gmail.com</td>
                                        
                                        
                                        <td>
                                            <div className='text-center'>
                                                {/* <i className="icon1"><IoEye /></i>  */}
                                                 <i className="icon2 pointer"><PiNotePencilFill onClick={() => setVisibleEdit(true)} /></i>
                                                <i className="icon3 pointer"><RiDeleteBin6Line onClick={() => setVisibleBlockOrDelete(true)}  /></i> 
                                            </div>
                                        </td>
                                    </tr>
                                    <tr >
                                     {/* onClick(()={    navigate("") }) */}
                                        
                                        <td>Roodra Lata</td>
                                        <td>test@gmail.com</td>
                                        
                                        
                                        <td>
                                            <div className='text-center'>
                                                {/* <i className="icon1"><IoEye /></i>  */}
                                                 <i className="icon2 pointer"><PiNotePencilFill onClick={() => setVisibleEdit(true)} /></i>
                                                <i className="icon3 pointer"><RiDeleteBin6Line onClick={() => setVisibleBlockOrDelete(true)}  /></i> 
                                            </div>
                                        </td>
                                    </tr>
                                    <tr >
                                     {/* onClick(()={    navigate("") }) */}
                                        
                                        <td>Roodra Lata</td>
                                        <td>test@gmail.com</td>
                                        
                                        
                                        <td>
                                            <div className='text-center'>
                                                {/* <i className="icon1"><IoEye /></i>  */}
                                                 <i className="icon2 pointer"><PiNotePencilFill onClick={() => setVisibleEdit(true)} /></i>
                                                <i className="icon3 pointer"><RiDeleteBin6Line onClick={() => setVisibleBlockOrDelete(true)}  /></i> 
                                            </div>
                                        </td>
                                    </tr>
                                {/* ))} */}
                            </tbody>
                        </table>
                        </TableContainer>
                        <Dialog
                            // header="Add User"
                            visible={visibleEdit}
                            onHide={() => setVisibleEdit(false)}
                            style={{ width: "50vw" }}
                            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                        >
                            <div className="editUserParent">
                                <div className="editUserCard">
                                    <form action="" className="editUserUserDetails">


                                        <div className="editUserHeaderDataForm">
                                        <div className=' d-flex justify-content-between' >
                                          <span className='heading1 '>Edit Member</span>
                                          <span className='BlockButton ' onClick={() => setVisibleBlock(true)}>Block</span>
                                        </div>
                                            <div className="userName">
                                                <label htmlFor="userName">
                                                    <p className="Label"> Name
</p>
                                                    <p className="medatoryLabel">*</p>
                                                </label>
                                                <input type="text" placeholder="Enter User Name" />
                                            </div>
                                            <div className="userEmail ">
                                                <label htmlFor="userEmail">
                                                    <p className="Label">Position</p>
                                                    <p className="medatoryLabel">*</p>
                                                </label>
                                                <select className="form-select" aria-label="Default select example">
                                <option selected>Select Team Leader</option>
                               
                                <option value={1}><input type="checkbox"/> </option>
                                
                            </select>
                                            </div>
                                        </div>
                                        <div className="editUserBottom">
                                            <button
                                                type="button"
                                                className="cancel p-dialog-close"
                                                onClick={() => setVisibleEdit(false)}
                                            >
                                                Cancel
                                            </button>
                                            <button className="saveChanges">Save Changes</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Dialog>

                        <Dialog
        // position="top"
        // header="Add User"
        visible={VisibleBlockOrDelete}
        onHide={() => setVisibleBlockOrDelete(false)}
        style={{ width: "auto" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="deleteParent">
          <div className="deletePopup">
            <div className="popupText">
              <h1 className='heading1'> Delete Team</h1>
              <p>Are you sure you want to delete this member ?</p>
            </div>
            <div className="popupButtons">
              <button className="deleteButton">
              Delete
                  {/* <RiDeleteBin6Line />
                 : (
                  <MdBlockFlipped />
                ) */}
              </button>
              <button
                className="cancelButton"
                type="button"
                onClick={() => setVisibleBlockOrDelete(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        // position="top"
        // header="Add User"
        visible={VisibleBlock}
        onHide={() => setVisibleBlock(false)}
        style={{ width: "auto" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="deleteParent">
          <div className="deletePopup">
            <div className="popupText">
              <h1 className='heading1'> Block Member</h1>
              <p>Are you sure you want to block  this member ?</p>
            </div>
            <div className="popupButtons">
              <button className="deleteButton">
        Block
                  {/* <RiDeleteBin6Line />
                 : (
                  <MdBlockFlipped />
                ) */}
              </button>
              <button
                className="cancelButton"
                type="button"
                onClick={() => setVisibleBlock(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Dialog>
  </div>
  </>
  )
}

export default TeamMember