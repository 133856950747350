import React, { useEffect, useRef, useState } from "react";
import "./AddOrEditUser.css";
import { Dialog } from "primereact/dialog";
import { MdBlockFlipped } from "react-icons/md";
import { PrimaryButton } from "../../../Buttons/PrimaryButton";
import { PiNotePencilFill } from "react-icons/pi";
import DeleteOrBlockUser from './DeleteOrBlockUser'

function AddOrEditUser({ type}) {
  const [visible, setVisible] = useState(false);
  return (
    <div className="addOrEditUser">
      <div className="addUserForm">
        {type === "Add" ? (
          <PrimaryButton
            label="Add User"
            addIcon={true}
            onClick={() => setVisible(true)}
          />
        ) : (
          <PiNotePencilFill onClick={() => setVisible(true)}/>
        )}

        <Dialog
          // header="Add User"
          visible={visible}
          onHide={() => setVisible(false)}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <div className="editUserParent">
            <div className="editUserCard">
              <form action="" className="editUserUserDetails">
                <div className="editUserHeader">
                  <div className="editUserHeaderLeft">
                    <h1>{type} User</h1>
                  </div>
                  <div className="editUserHeaderRight">
                    {type === "Edit" ? (
                      <DeleteOrBlockUser action="block" onClick={() => setVisible(false)}/>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="editUserHeaderDataForm">
                  <div className="userName">
                    <label htmlFor="userName">
                      <p className="inputLabel">Name</p>
                      <p className="medatoryLabel">*</p>
                    </label>
                    <input type="text" placeholder="Enter User Name" />
                  </div>
                  <div className="userEmail">
                    <label htmlFor="userEmail">
                      <p className="inputLabel">Email</p>
                      <p className="medatoryLabel">*</p>
                    </label>
                    <input type="email" placeholder="Enter User Email" />
                  </div>
                </div>
                <div className="editUserBottom">
                  <button
                    type="button"
                    className="cancel p-dialog-close"
                    onClick={() => setVisible(false)}
                  >
                    Cancel
                  </button>
                  <button className="saveChanges">Save Changes</button>
                </div>
              </form>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
}

export default AddOrEditUser;
