import React, { useState } from 'react'
import "./inputs.css"
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
export const PasswordInput = ({ error, className, ...props }) => {
    let [type, setType] = useState("password")
    const toggleEye = () => setType(type === "password" ? "text" : "password")
    return (
        <div className='passwordInput_con'>
            <input type={type} {...props} className={`custom_input ${className} ${error ? "invalid_input" : ""} `} />
            <div className='eye'>
                {type === "password" ? <AiOutlineEyeInvisible onClick={toggleEye} /> : <AiOutlineEye onClick={toggleEye} />}
            </div>
        </div>
    )
}
