import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import english from "./en.json"
import hindi from "./hi.json"
i18n
    .use(initReactI18next)
    .init({
        resources: {
            debug: true,
            en: {
                translation: { ...english }
            },
            hi: {
                translation: {...hindi }
            }
        },
        lng: "en",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    });