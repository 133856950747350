import React from 'react'
import "./common.css"

export const Logo = () => {
    return (
        <div className='logo_con flex align_center'>
            <div>
                <svg width="45" height="40" viewBox="0 0 45 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.375 2.22266L32.625 2.22266L36.375 7.83669L9.5 7.83669L5.75 12.8269L38.875 12.8269L42 17.8172L3.25 17.8172L2 19.6885L3.25 22.8075L42 22.8075L38.875 27.7977L6.375 27.7977L9.5 32.1642L36.375 32.1642L32.625 37.7782L12.625 37.7782" stroke="white" strokeWidth="3" />
                </svg>
            </div>
            <div className='logo_text'>BVokal</div>
        </div>
    )
}
