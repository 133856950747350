import React from 'react';
import { NoDataPage } from '../../../components/common/NoDataPage';

function UserManagement() {
  return (
    <div>
      <NoDataPage num={1}/>
    </div>
  );
}

export default UserManagement;
