import React, { useEffect, useRef, useState } from "react";
import "./common.css";
import girl from "../../assests/images/girl.png";
import { IoIosAdd } from "react-icons/io";
import { PiNotePencilFill } from "react-icons/pi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TableContainer } from "../../components/common/TableContainer";
import { PrimaryButton } from "../Buttons/PrimaryButton.jsx";
import AddUserDilog from "../PagesComponents/Admin/UserManagement/AddOrEditUser.jsx";
import DeleteUser from "../PagesComponents/Admin/UserManagement/DeleteOrBlockUser.jsx";
import profile from "../../assests/images/profileUserManagement.png"

export const NoDataPage = ({num}) => {
  const hasTableData = num

  return (
    <div className="noData_page_con">
      {hasTableData ? (
        <div className="user_management_con">
          <TableContainer className={"table_container"}>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th className="text-end">
                    <div className="d-flex justify-content-end">
                      <AddUserDilog type="Add" />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="firstColumn">
                      <img src={profile} alt="Image" />
                      <p>name1</p>
                    </div>
                  </td>
                  <td>email1</td>
                  <td>
                    <div className="text-end">
                      <i className="icon2">
                        <AddUserDilog type="Edit" />
                      </i>
                      <i className="icon3">
                        <DeleteUser action="delete" />
                      </i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </TableContainer>
        </div>
      ) : (
        <div className="defaultParent">
          <div className="defaultImgBox">
            <img src={girl} alt="" />
          </div>
          <div className="defaultDataBox">
            <h3>No User Added </h3>
            <p>Add New user to start Managing users </p>
            <AddUserDilog type="Add" />
          </div>
        </div>
      )}
    </div>
  );
};
